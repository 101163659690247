import React, { Suspense, useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import ControlPanel from './ControlPanel';
import ImageGallery, { Image } from './ImageGallery';
import { RotatingSquare } from 'react-loader-spinner'
import Markdown from 'react-markdown'
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import gsap from 'gsap';
import './Viewer.css';

interface Model3dProps {
  url: string;
  setPOIShouldBeDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Model {
  file: string;
  title: string;
  cameraInitialPosition: {
    x: number;
    y: number;
    z: number;
  };
  bbox: {
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
    zmin: number;
    zmax: number;
  }
  pois: POI[];
  images: Image[];
  text: string;
}

interface POI {
  title: string;
  description: string;
  position: {
    x: number;
    y: number;
    z: number;
  };
}

const Loader: React.FC = () => {
  return (
    <Suspense fallback={null}>
      <div className="loading-spinner">
        <RotatingSquare visible={true}
          height="150"
          width="150"
          color="#21386b"
          ariaLabel="rotating-square-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </Suspense>
  )
}

const Model3d: React.FC<Model3dProps> = ({ url, setPOIShouldBeDisplayed }) => {
  const { scene } = useGLTF(url);

  useEffect(() => {
    //const bbox = new THREE.Box3().setFromObject(scene);
    //console.log("box", bbox)

    scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
      }

    });
  }, [scene, setPOIShouldBeDisplayed]);

  const handlePointerOver = () => setPOIShouldBeDisplayed(true);
  const handlePointerOut = () => setPOIShouldBeDisplayed(false);

  return <primitive object={scene} onPointerOver={handlePointerOver} onPointerOut={handlePointerOut} />;
}

const POIMarker: React.FC<{ poi: POI; onClick: () => void }> = ({ poi, onClick }) => {

  return (
    <mesh
      position={[poi.position.x, poi.position.y, poi.position.z]}
      onClick={onClick}
    >
      <mesh position={[0, 0, 0.005]}>
        <circleGeometry args={[0.03, 32]} />
        <meshBasicMaterial color="white" />
      </mesh>
      <mesh position={[0, 0, 0.006]}>
        <circleGeometry args={[0.018, 32]} />
        <meshBasicMaterial color="#CD4B06" />
      </mesh>
    </mesh>
  );
};

const Scene: React.FC<{
  model: Model | null,
  onPOIClick: (poi: POI | null) => void,
  selectedPOI: POI | null,
  setSelectedPOI: React.Dispatch<React.SetStateAction<POI | null>>
  setEncartPosition: (pos: { left: number, top: number }) => void,
  setPoiPosition: (pos: { x: number, y: number }) => void,
  controlsRef: React.RefObject<any>,
  rotateCameraLeft: () => void,
  rotateCameraRight: () => void,
  zoom: number,
  showMarkers: boolean,
  isPOIShouldBeDisplayed: boolean,
  setPOIShouldBeDisplayed: React.Dispatch<React.SetStateAction<boolean>>
  imageGalleryHidden: boolean,
  setImageGalleryHidden: React.Dispatch<React.SetStateAction<boolean>>
  moreHidden: boolean,
  setMoreHidden: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  model,
  onPOIClick,
  selectedPOI,
  setSelectedPOI,
  setEncartPosition,
  setPoiPosition,
  controlsRef,
  rotateCameraLeft,
  rotateCameraRight,
  zoom,
  showMarkers,
  isPOIShouldBeDisplayed,
  setPOIShouldBeDisplayed,
  imageGalleryHidden,
  setImageGalleryHidden,
  moreHidden,
  setMoreHidden
}) => {
    const { camera, gl } = useThree();

    const putObjectOnLeftForPOIDisplay = (camera: THREE.Camera, cb: () => void) => {
      if (model) {
        if (
          Math.abs(camera.position.x - model.cameraInitialPosition.x + (model.bbox.xmax - model.bbox.xmin)) < 0.05
          && Math.abs(camera.position.y - model.cameraInitialPosition.y) < 0.05
          && Math.abs(camera.position.z - model.cameraInitialPosition.z) < 0.05
        ) {
          // No animation to do
          cb();
        } else {
          // Animate the camera position & target change and then display the POI.
          // Camera position
          const targetLookAt = new THREE.Vector3((model.bbox.xmax - model.bbox.xmin) / 3, (model.bbox.ymax - model.bbox.ymin) / 2, 0);
          gsap.to(camera.position, {
            duration: 1,
            x: model?.cameraInitialPosition.x + (model.bbox.xmax - model.bbox.xmin) / 3,
            y: model?.cameraInitialPosition.y,
            z: model?.cameraInitialPosition.z,
            onUpdate: () => {
              controlsRef.current.update();
            },
            onComplete: () => {
              cb();
            }
          });

          // Animate the target change at the same time
          gsap.to(controlsRef.current.target, {
            duration: 1,
            x: targetLookAt.x,
            y: targetLookAt.y,
            z: targetLookAt.z,
            onUpdate: () => {
              controlsRef.current.update();
            }
          });
        }
      }
    }

    const resetCameraPosition = () => {
      if (model) {
        const targetLookAt = new THREE.Vector3(0, (model.bbox.ymax - model.bbox.ymin) / 2, 0);
        gsap.to(camera.position, {
          duration: 1,
          x: model?.cameraInitialPosition.x,
          y: model?.cameraInitialPosition.y,
          z: model?.cameraInitialPosition.z,
          onUpdate: () => {
            controlsRef.current.update();
          },
        });
        // Animate the target change at the same time
        gsap.to(controlsRef.current.target, {
          duration: 1,
          x: targetLookAt.x,
          y: targetLookAt.y,
          z: targetLookAt.z,
          onUpdate: () => {
            controlsRef.current.update();
          }
        });
      }
    }

    const handlePOIClick = useCallback((poi: POI) => {
      // Reinitialize camera position smoothly (if needed)
      if (controlsRef.current && model) {
        putObjectOnLeftForPOIDisplay(camera, () => { setImageGalleryHidden(true); setMoreHidden(true); onPOIClick(poi) })
      }
    }, [onPOIClick, camera]);

    useEffect(() => {
      if (!selectedPOI && (imageGalleryHidden || moreHidden)) {
        resetCameraPosition()
      }
      if (!selectedPOI && (!imageGalleryHidden || !moreHidden)) {
        // Put object on left when image gallery is displayed but no selected poi
        putObjectOnLeftForPOIDisplay(camera, () => { })
      }
      if (selectedPOI && (!imageGalleryHidden || !moreHidden)) {
        // Hide selected POI
        setSelectedPOI(null)
      }
    }, [selectedPOI, imageGalleryHidden, moreHidden])

    useFrame(() => {
      if (model) {
        const vectorEncart = new THREE.Vector3(model.bbox.xmax, (model.bbox.ymax - model.bbox.ymin) / 2, 0); // Right of the model, y centered)
        vectorEncart.project(camera);
        const xEncart = (vectorEncart.x * 0.5 + 0.5) * gl.domElement.clientWidth;
        const yEncart = (vectorEncart.y * -0.5 + 0.5) * gl.domElement.clientHeight;
        setEncartPosition({ left: xEncart + 20, top: yEncart });

        if (selectedPOI) {
          const vectorPOI = new THREE.Vector3(selectedPOI.position.x, selectedPOI.position.y, selectedPOI.position.z);

          vectorPOI.project(camera);

          const x = (vectorPOI.x * 0.5 + 0.5) * gl.domElement.clientWidth;
          const y = (vectorPOI.y * -0.5 + 0.5) * gl.domElement.clientHeight;

          setPoiPosition({ x, y });
        }
      }
    });


    useFrame(() => {
      rotateCameraLeft();
      rotateCameraRight();
      if (camera.zoom !== zoom) {
        camera.zoom = zoom;
        camera.updateProjectionMatrix();
      }
    });



    if (!model) {
      return null
    }

    return (
      <>
        <spotLight position={[0, 15, 1]} intensity={100} castShadow />
        <spotLight position={[0, 15, -1]} intensity={100} castShadow />

        <ambientLight color={"#FFFFFF"} intensity={model.file === "savoie.glb" ? 4 : 2.5} />
        <Model3d url={`/3d/${model?.file}`} setPOIShouldBeDisplayed={setPOIShouldBeDisplayed} />
        {(showMarkers || isPOIShouldBeDisplayed) && model?.pois.map((poi, index) => (
          <POIMarker key={index} poi={poi} onClick={() => handlePOIClick(poi)} />
        ))}
        <mesh
          receiveShadow
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, model?.bbox.ymin - 0.07, 0]}
          material={new THREE.ShadowMaterial({ opacity: 0.2 })}
        >
          <planeGeometry args={[2, 2]} />
        </mesh>

        <OrbitControls ref={controlsRef} enableZoom={false} />
      </>
    );
  };

const Viewer: React.FC = () => {
  const { modelId } = useParams()
  const controlsRef = useRef<any>(null);

  const [model, setModel] = useState<Model | null>(null);
  const [selectedPOI, setSelectedPOI] = useState<POI | null>(null);
  const [encartPosition, setEncartPosition] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
  const [poiPosition, setPoiPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isRotatingLeft, setIsRotatingLeft] = useState(false);
  const [isRotatingRight, setIsRotatingRight] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [imageGalleryHidden, setImageGalleryHidden] = useState(true);
  const [moreHidden, setMoreHidden] = useState(true);
  const [isPOIShouldBeDisplayed, setPOIShouldBeDisplayed] = useState(false);

  useEffect(() => {
    fetch(`/3d/${modelId}.json`)
      .then((response) => response.json())
      .then((data) => setModel(data));
  }, [modelId]);

  const handleClose = () => {
    setSelectedPOI(null);
  };

  if (!model) {
    return <></>
  }

  const rotateCameraLeft = () => {
    if (isRotatingLeft && controlsRef.current) {
      controlsRef.current.update();
      controlsRef.current.object.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), 0.01);
    }
  };

  const rotateCameraRight = () => {
    if (isRotatingRight && controlsRef.current) {
      controlsRef.current.update();
      controlsRef.current.object.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), -0.01);
    }
  };

  const onScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    const newZoom = zoom + (e.deltaY < 0 ? 1 : -1) * 0.1;
    if (newZoom >= 1 && newZoom <= 10) {
      setZoom(Math.round(newZoom * 10) / 10);
    }
  }

  return (
    <div className="viewer">

      <Suspense fallback={<Loader />}>
        <Canvas
          shadows
          camera={{ position: [model.cameraInitialPosition.x, model.cameraInitialPosition.y, model.cameraInitialPosition.z], fov: 75 }}
          onCreated={({ camera, gl, scene }) => {
            const controls = controlsRef.current;
            controls?.target.set((model.bbox.xmax + model.bbox.xmin) / 2, (model.bbox.ymax + model.bbox.ymin) / 2, (model.bbox.zmax + model.bbox.zmin) / 2); // Initial target position on model center
            controls?.update();
            gl.shadowMap.enabled = true;
            gl.shadowMap.type = THREE.PCFSoftShadowMap;
            scene.background = new THREE.Color('#F0F0F0');
          }}
          onWheel={(e) => onScroll(e)}
        >
          <Scene
            model={model}
            onPOIClick={setSelectedPOI}
            selectedPOI={selectedPOI}
            setSelectedPOI={setSelectedPOI}
            setEncartPosition={setEncartPosition}
            setPoiPosition={setPoiPosition}
            controlsRef={controlsRef}
            rotateCameraLeft={rotateCameraLeft}
            rotateCameraRight={rotateCameraRight}
            zoom={zoom}
            showMarkers={!!selectedPOI}
            setPOIShouldBeDisplayed={setPOIShouldBeDisplayed}
            isPOIShouldBeDisplayed={isPOIShouldBeDisplayed}
            setImageGalleryHidden={setImageGalleryHidden}
            setMoreHidden={setMoreHidden}
            imageGalleryHidden={imageGalleryHidden}
            moreHidden={moreHidden}
          />
        </Canvas>
      </Suspense>
      {selectedPOI && (
        <>
          <div
            className="poi-info-wrapper"
            style={{ left: encartPosition.left, top: encartPosition.top }}
          >
            <div
              className="poi-info-inner"
            >
              <h2>{selectedPOI.title}</h2>
              <Markdown>{selectedPOI.description}</Markdown>
              <p className="poi-info-actions">
                <button onClick={handleClose}>Fermer</button>
              </p>
            </div>
          </div>
          <svg
            className="line-svg"
            style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
            width="100%"
            height="100%"
          >
            <defs>
              <filter id="dropShadow" x="0" y="0" width="200%" height="200%">
                <feOffset result="offOut" in="SourceAlpha" dx="2" dy="2" />
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="8" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
            <line
              x1={poiPosition.x}
              y1={poiPosition.y}
              x2={encartPosition.left}
              y2={encartPosition.top + 20}
              stroke="white"
              strokeWidth="8"
            />
          </svg>
        </>
      )}
      <ControlPanel rotateLeft={(rotating) => setIsRotatingLeft(rotating)} rotateRight={(rotating) => setIsRotatingRight(rotating)} zoom={zoom} setZoom={setZoom} displayImageGalleryButton={model.images.length > 0} setImageGalleryHidden={setImageGalleryHidden} setMoreHidden={setMoreHidden} title={model.title}/>
      <ImageGallery images={model.images} position={encartPosition} hidden={imageGalleryHidden} setHidden={setImageGalleryHidden} />
      {!moreHidden && (
        <>
          <div
            className="poi-info-wrapper"
            style={{ left: encartPosition.left, top: encartPosition.top}}
          >
            <div
              className="poi-info-inner"
            >
              <h2>{model.title}</h2>
              <Markdown>{model.text}</Markdown>
              <p className="poi-info-actions">
                <button onClick={() => setMoreHidden(true)}>Fermer</button>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};



export default Viewer;
