import React from 'react';
import Viewer from './pages/viewer/Viewer';
import { Routes, Route } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/viewer/:modelId" element={<Viewer />} />
    </Routes>
  )
};



export default App;
