import React, { useState } from 'react';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

export interface Image {
  caption: string;
  filepath_large: string;
  filepath_small: string;
}

const ModalImageView: React.FC<{ isOpen: boolean; onClose: () => void; image: Image | null }> = ({ isOpen, onClose, image }) => {
  if (!isOpen || !image)
    return <></>;

  return (
    <Lightbox
      mainSrc={image.filepath_large}
      onCloseRequest={() => onClose()}
      imageCaption={image.caption}
    />
  )
};



const ImageGallery: React.FC<{ images: Image[], position: { left: number; top: number }, setHidden: React.Dispatch<React.SetStateAction<boolean>>, hidden: boolean }> = ({ images, position, setHidden, hidden }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const handleClose = () => setHidden(true)

  const onImageClick = (image: Image) => {
    setSelectedImage(image);
    handleOpenModal()
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (hidden) {
    return <></>
  }

  return <>
    <div
      className="gallery-wrapper"
      style={{ left: position.left, top: position.top }}
    >
      <div
        className="gallery-inner"
      >
        <div className="image-gallery">
          {images.map((image, index) => {
            return <div className="image-item" key={index} onClick={() => onImageClick(image)}>
              <div className="image-container">
                <img src={image.filepath_small} alt={image.caption} />
                <div className="image-caption">
                  <p className='image-legend'>{image.caption}</p>
                  <i className="ic_zoom_in"></i>
                </div>
              </div>
            </div>
          })}
        </div>
        <p className="gallery-info-actions">
          <button onClick={handleClose}>Fermer</button>
        </p>
      </div>
    </div>
    <ModalImageView image={selectedImage} isOpen={isModalOpen} onClose={handleCloseModal} />
  </>
}

export default ImageGallery;