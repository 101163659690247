import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

const ControlPanel: React.FC<{ rotateRight: (rotation: boolean) => void, rotateLeft: (rotation: boolean) => void, zoom: number, setZoom: React.Dispatch<React.SetStateAction<number>>, setImageGalleryHidden: React.Dispatch<React.SetStateAction<boolean>>, setMoreHidden: React.Dispatch<React.SetStateAction<boolean>>, displayImageGalleryButton: boolean, title: string }> = ({ rotateRight, rotateLeft, zoom, setZoom, setImageGalleryHidden, setMoreHidden, displayImageGalleryButton, title }) => {
  const [hidden, setHidden] = useState(false);
  const [titleDisplayed, setTitleDisplayed] = useState<ReactElement<any, any>>();

  useEffect(() => {
    if(title.includes("Fox Battery")){
      setTitleDisplayed(<div><i>Fox Battery</i>{title.replace("Fox Battery", "")}</div>)
    } else {
      setTitleDisplayed(<div>{title}</div>)
    }
  }, [title])
  

  const handleZoomChange = (e: ChangeEvent<HTMLInputElement>) => {
    setZoom(parseFloat(e.target.value));
  };

  const handleZoomIn = () => {
    setZoom((prevValue: number) => Math.min(Math.round((prevValue + 0.1) * 10) / 10, 10))
  };

  const handleZoomOut = () => {
    setZoom((prevValue: number) => Math.max(Math.round((prevValue - 0.1) * 10) / 10, 1))
  };

  return (
    <>
      <div className="control-panel" hidden={hidden}>
        <div className="control-panel-top-left">
          <div className="hide-controls control-panel-item">
            <button className="button" onClick={() => { setHidden(true) }} style={{ borderRadius: 20 }}>
              <i className="ic_visibility_off" style={{ fontSize: 32, marginRight: 5 }} />
              <span>
                Masquer l'interface
              </span>
            </button>
          </div>
          <div className="hide-controls control-panel-item">
            <div className="card">
              <div style={{ fontWeight: "bold", marginLeft: 5, marginBottom: 10 }}>Contrôles</div>
              <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                <i className="ic_left_clic" style={{ fontSize: 32, marginRight: 5 }} />
                <span style={{ fontSize: 12 }}>
                  Maintenez le bouton gauche de la souris pour déplacer la caméra.
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                <i className="ic_right_clic" style={{ fontSize: 32, marginRight: 5 }} />
                <span style={{ fontSize: 12 }}>
                  Maintenez le bouton droit de la souris pour un déplacement latéral.
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                <i className="ic_mouse_wheel" style={{ fontSize: 32, marginRight: 5 }} />
                <span style={{ fontSize: 12 }}>
                  Utilisez la roue de la souris pour zoomer ou dézoomer.
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <i className="ic_mouse_cursor" style={{ fontSize: 32, marginRight: 5 }} />
                <span style={{ fontSize: 12 }}>
                Survolez l’insigne avec la souris pour faire apparaitre ses différents focus.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="control-panel-bottom-left">
          <div className="hide-controls control-panel-item">
            <div style={{ display: "flex" }}>
              <button className="button" onMouseDown={() => { rotateRight(true) }} onMouseUp={() => rotateRight(false)} style={{ borderRadius: 20, display: "block", width: 170, marginRight: 10 }}>
                <i className="ic_rotate_right" style={{ fontSize: 32, marginRight: 5, display: "block" }} />
                <span style={{ display: "block" }}>
                  Pivoter à droite
                </span>
              </button>
              <button className="button" onMouseDown={() => { rotateLeft(true) }} onMouseUp={() => rotateLeft(false)} style={{ borderRadius: 20, display: "block", width: 170 }}>
                <i className="ic_rotate_left" style={{ fontSize: 32, marginRight: 5, display: "block" }} />
                <span style={{ display: "block" }}>
                  Pivoter à gauche
                </span>
              </button>
            </div>
          </div>
          <div className="hide-controls control-panel-item">
            <div className="card" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
              <i onClick={handleZoomOut} className="ic_zoom_out" style={{ fontSize: 32 }}></i>
              <input
                type="range"
                min="1"
                max="10"
                step="0.1"
                value={zoom}
                onChange={handleZoomChange}
                style={{ flexGrow: 1, accentColor: "#21386B" }}
              />
              <i onClick={handleZoomIn} className="ic_zoom_in" style={{ fontSize: 32, cursor: "pointer" }}></i>
            </div>
          </div>
        </div>
        {
          displayImageGalleryButton &&
          <div className="control-panel-top-right">
            <div className="hide-controls control-panel-item">
              <button className="button-photo" onClick={() => { setImageGalleryHidden(false); setMoreHidden(true) }} style={{ borderRadius: 20, padding: '8px 13px' }}>
                <i className="ic_collection" style={{ fontSize: 38, marginRight: 5 }} />
                <span style={({ fontSize: 16 })}>
                  Documents d'archives
                </span>
              </button>
            </div>
          </div>
        }
        <div className="control-panel-bottom-right">
          <div className="hide-controls control-panel-item">
            <button className="button-photo" onClick={() => { setMoreHidden(false); setImageGalleryHidden(true) }} style={{ borderRadius: 20, padding: '15px 15px' }}>
              <span style={({ fontSize: 16 })}>
                En savoir plus
              </span>
            </button>
          </div>
        </div>
      </div>
      {!hidden && <div className="title">{titleDisplayed}</div>}
      <div className="control-panel" hidden={!hidden}>
        <div className="control-panel-top-left">
          <div className="hide-controls control-panel-item">
            <button className="button" onClick={() => { setHidden(false) }} style={{ borderRadius: 20 }}>
              <i className="ic_visibility" style={{ fontSize: 32, marginRight: 5 }} />
              <span>
                Afficher l'interface
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ControlPanel;
